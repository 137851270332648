<template>
  <div :key="chat.id" :class="$vuetify.breakpoint.name != 'xs' ? 'mx-3 mb-4' : 'mb-8 mx-0'">
    <div class="d-flex justify-start mb-4" v-if="chat.type == 0">
      <div v-if="$vuetify.breakpoint.name != 'xs'" class="img_cont_msg">
        <img :src="`https://placehold.co/600x600/34495e/ecf0f1?text=${chat.name[0].toUpperCase()}`" :alt="`${chat.name}`" class="rounded-circle user_img_msg"/>
      </div>
      <div class="msg_cotainer">
        <div style="position: static;" class="font-weight-bold text-capitalize">{{ chat.name }}
          <span class="font-weight-light ml-2" style="font-size: 10px;">{{ datetime }}</span>
        </div>
        <div class="mb-3" style="font-size: 13px;" v-html="chat.message"></div>
      </div>
    </div>
    <div class="d-flex justify-end mb-4" v-else>
      <div class="msg_cotainer_send">
        <div style="position: static;" class="font-weight-bold text-capitalize">{{ chat.name }} 
          <span style="font-size: 11px;">[{{ chat.license }}]</span>
          <span class="font-weight-light ml-2" style="font-size: 10px;">{{ datetime }}</span>
        </div>
        <div style="background: #fdcb6e90 !important;" class="px-2 py-1 rounded-lg">
          <div>
            <div class="font-weight-black" style="font-size: 9px; ">Topik:</div>
            <div class="font-weight-bold" style="font-size: 8px; line-height: 1em; color: #636e72">{{ chat.topic.name }}</div>
          </div>
          <div>
            <div class="font-weight-black" style="font-size: 9px;">Aktifitas:</div>
            <div class="font-weight-bold pb-2" style="font-size: 8px;line-height: 1em; color: #636e72">{{ chat.activity.name }}</div>
          </div>
        </div>
        <div class="mb-3" style="font-size: 13px;" v-html="chat.message"></div>
      </div>
      <div v-if="$vuetify.breakpoint.name != 'xs'" class="img_cont_msg">
        <img :src="`https://placehold.co/600x600/34495e/ecf0f1?text=${chat.name[0].toUpperCase()}`" :alt="`${chat.name}`" class="rounded-circle user_img_msg"/>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: "Chat",
  props: ["chat"],
  computed: {
    ...mapState('roomModule', {
      users: state => state.users
    }),
    sender: function () {
      var users = this.users.filter((val) => {
        return val.id == this.chat.senderID;
      });

      return users.length > 0 ? users[0] : {};
    },
    avatar: function () {
      return this.sender ? this.sender.photoUrl : "";
    },
    senderName: function () {
      return this.sender ? this.sender.name : "Unknown";
    },
    datetime: function () {
      return moment(this.chat.created_at.toDate()).format("DD MMM YYYY HH:mm");
      // return moment(this.chat.created_at.toDate()).format("h:mm a, Do MMM YYYY");
    }
  },
  mounted() {
    // var element = document.getElementById("chat-list-container");
    // if (typeof (element) != 'undefined' && element != null) {
    //   element.scrollTop = element.scrollHeight;
    // }
  }
}
</script>
<style>
  .user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
  }
  .msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    font-size: 12px;
    max-width: 300px;
    border-radius: 0 10px 0 10px;
    background-color: #E5E9F2;
    padding: 10px;
    position: relative;
  }

  .msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 12px;
    max-width: 300px;
    border-radius: 10px 0 10px 0;
    background-color: #FDF7B2;
    padding: 10px;
    position: relative;
  }
</style>