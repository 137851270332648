import axios from 'axios'
import url from './api'

const offline = false

const state = () => {
  return {
    questions: []
  }
};

const mutations = {
  fetch(state, payload) {
    state.questions = payload.data.list
  },
  clear(state) {
    state.questions = []
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
};

const getters = {
  detail: state => id => {
    return state.questions.find(question => question.id == id)
  },
  list: () => params => {
    let offset = (params.page - 1) * params.page
    let data = JSON.parse(localStorage.getItem('ytc_questions')) || [];
    return {
      limit: params.limit,
      list: data.slice().splice(offset, params.limit),
      page: params.page,
      total: data.length
    }
  }
};

const actions = {
  async list({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      if (offline) {
        setTimeout(() => {
          resolve(url.success(getters.list(params)))
        }, 1000);
      } else {
        axios.get(url.question_list, {
          params: Object.assign({ page: 1, limit: 10, type: 'single_choice' }, params)
        })
          .then(res => { commit('fetch', res.data); resolve(res.data) })
          .catch(error => { commit('clear'); reject(error) })
      }
    })
  },

  async detail({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      let data = getters.detail(id)
      if (data) {
        resolve(url.success(data))
      } else {
        if (offline) {
          setTimeout(() => {
            let questions = JSON.parse(localStorage.getItem('ytc_questions')) || []
            let question = questions.find(question => question.id == id)
            if (question) {
              resolve(url.success(question))
            } else {
              reject(url.error('Question not found'))
            }
          }, 500);
        } else {
          axios.get(url.question_detail.replace('{id}', id))
            .then(res => { commit('success'); resolve(res.data) })
            .catch(error => { commit('error'); reject(error) })
        }
      }
    })
  },

  async detail_public({ commit }, {id, company_id}) {
    return new Promise((resolve, reject) => {
      axios.get(url.question_detail_public.replace('{id}', id), {
        headers: {
          'X-Company-ID': company_id
        }
      })
        .then(res => { commit('success'); resolve(res.data) })
        .catch(error => { commit('error'); reject(error) })
    })
  },

};

export default { namespaced: true, state, mutations, actions, getters }