<template>
  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
    <div class="font-weight-bold mb-3">{{ act.title }}</div>
    <v-col cols="12" class="pa-0">
      <v-card elevation="0">
        <template v-if="is_scheduled.final">
          <v-responsive :aspect-ratio="16 / 9">
            <vue-plyr id="videoElement" ref="plyr" seekTime="1" :options="option" preload="auto">
              <video
                id="video-test"
                width="100%"
                height="auto"
                preload="auto"
                controls
                playsinline
                @ended="handleEnded"
                controlslist="nodownload noremoteplayback noplaybackrate"
                data-poster="poster.jpg"
                class="video-test"
              >
                <source :src="video_url" type="video/mp4" />
              </video>
            </vue-plyr>
          </v-responsive>
          <div id="desc-video" class="pa-4">
            <p
              class="text-body-2 text-justify mb-4"
              v-html="act.theory.description"
            ></p>
          </div>
        </template>
        <!-- <div v-else-if="is_scheduled.day_date" class="pa-4">
          <div>Video akan bisa diakses pada:</div>
          <div
            class="font-weight-bold"
            v-for="(item, index) in schedules"
            :key="index"
          >
            <template
              v-if="
                setting.schedules_data.is_day == '1' &&
                days[timeIsIt.day] == item.day
              "
            >
              <span> Hari {{ day(item.day) }}, </span>
              <template v-for="(time, index2) in sortTimes(item.times)">
                <span
                  :key="index2"
                  v-if="
                    current_hour <= time.start_time &&
                    (!item.times[index2 - 1] ||
                      (item.times[index2 - 1] &&
                        item.times[index2 - 1].end_time > current_hour))
                  "
                >
                  pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </span>
              </template>
            </template>
            <template
              v-else-if="
                setting.schedules_data.is_day == '2' &&
                current_date == item.date.slice(0, 10)
              "
            >
              <span> Tanggal {{ item.date }}, </span>
              <template v-for="(time, index2) in sortTimes(item.times)">
                <span
                  :key="index2"
                  v-if="
                    current_hour <= time.start_time &&
                    (!item.times[index2 - 1] ||
                      (item.times[index2 - 1] &&
                        item.times[index2 - 1].end_time > current_hour))
                  "
                >
                  pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </span>
              </template>
            </template>
          </div>
        </div> -->
        <div v-else-if="setting.is_show_scheduled == '1'" class="pa-4">
           <div id="desc-video">
            <p
              class="text-justify mb-4"
              v-html="act.theory.description"
            ></p>
          </div>
          <div>Video akan bisa diakses pada:</div>
          <div
            class="font-weight-bold"
            v-for="(item, index) in schedules"
            :key="index"
          >
            <template v-if="setting.schedules_data.is_day == '1'">
              <span>Hari {{ day(item.day) }}</span>
              <ul>
                <li
                  v-for="(time, index2) in sortTimes(item.times)"
                  :key="index2"
                >
                  Pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </li>
              </ul>
            </template>
            <span v-else-if="setting.schedules_data.is_day == '2'">
              <span>Tanggal {{ date(item.date) }}</span>
              <ul>
                <li
                  v-for="(time, index2) in sortTimes(item.times)"
                  :key="index2"
                >
                  Pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </li>
              </ul>
            </span>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    act: { type: Object, default: () => ({}) },
  },
  computed: {
    timeIsIt() {
      const theDate = new Date();

      return {
        day: theDate.getDay(),
        date: theDate.getDate().toString().padStart(2, 0),
        month: (theDate.getMonth() + 1).toString().padStart(2, 0),
        year: theDate.getFullYear(),
        hour: theDate.getHours().toString().padStart(2, 0),
        minute: theDate.getMinutes().toString().padStart(2, 0),
      };
    },
    days() {
      return [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
    },
    option: () => ({
      keyboard: {
        focused: false,
        global: false,
      },
      seekTime: 1,
      displayDuration: false,
      mediaMetadata: { title: "", artist: "", album: "", artwork: [] },
    }),
    video_url() {
      return this.act?.theory?.file?.url;
    },
    setting() {
      return (this.act?.theory?.meta_data || []).reduce((car, val) => {
        if (["schedules_data"].includes(val.title))
          car[val.title] = JSON.parse(val.content);
        else car[val.title] = val.content;
        return car;
      }, {});
    },
    current_hour() {
      return `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;
    },
    current_date() {
      return `${this.timeIsIt.year}-${this.timeIsIt.month}-${this.timeIsIt.date}`;
    },
    is_scheduled() {
      let day_date = false,
        time = false,
        final = false;
      if (this.setting.is_scheduled == 1) {
        const schedules_data = this.setting?.schedules_data || {};
        const currentDay = this.timeIsIt.day;
        const currentDate = this.current_date;
        const currentHour = this.current_hour;

        switch (schedules_data.is_day) {
          case "1": // untuk hari
            final = schedules_data.days.some((val) => {
              if (this.days.findIndex((v) => v == val.day) == currentDay) {
                day_date = true;
                return val.times.some((val2) => {
                  // console.log(currentHour, val2.start_time, val2.end_time);
                  if (
                    currentHour >= val2.start_time &&
                    currentHour <= val2.end_time
                  ) {
                    time = true;
                    return true;
                  }
                });
              }
            });
            break;
          case "2": // untuk tanggal
            final = schedules_data.dates.some((val) => {
              if (currentDate == val.date.slice(0, 10)) {
                day_date = true;
                return val.times.some((val2) => {
                  if (
                    currentHour >= val2.start_time &&
                    currentHour <= val2.end_time
                  ) {
                    time = true;
                    return true;
                  }
                });
              }
            });
            break;
          default:
            break;
        }
      } else {
        day_date = time = final = true;
      }
      return { day_date, time, final };
    },
    schedules() {
      if (this.setting.schedules_data.is_day == "1") {
        return this.setting.schedules_data.days;
      } else if (this.setting.schedules_data.is_day == "2") {
        return this.setting.schedules_data.dates;
      }
      return [];
    },
  },
  watch: {
    
  },
  mounted() {
    this.useOnMoveMinimize();
    this.useOnMoveTab();
  },
  beforeDestroy() {
    document.onblur = null;
    document.onvisibilitychange = null;
  },
  methods: {
    useOnMoveTab() {
      document.onblur = () => {
        this.$refs.plyr.player.pause();
        // console.log('blur')
      };
    },
    useOnMoveMinimize() {
      document.onvisibilitychange = () => {
        this.$refs.plyr.player.pause();
        // console.log('minimes')
      };
    },
    handleEnded() {
      // console.log('ended video data');
      this.$emit("ended", this.act.is_complete);
    },
    date(a) {
      return a ? moment(a).locale("en-gb").format("L") : null;
    },
    sortTimes(times) {
      return times.sort((a, b) => {
        return b.start_time < a.start_time ? 1 : -1;
      });
    },
    day(day) {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const hari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      return hari[days.findIndex((v) => v == day)];
    },
  },
};
</script>
