<template>
  <v-container class="message-container">
    <!-- fill-height -->
    <!-- fluid -->
    <v-row justify="center" align="center" v-if="!isRoomSelected">
      <v-col class="col-xs-12">
        <RoomNotSelected />
      </v-col>
    </v-row>
    <ChatContainer v-if="isRoomSelected"  :act="act" :topic="topic" :detailClass="detailClass" />

  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import RoomNotSelected from './RoomNotSelected';
import ChatContainer from './ChatContainer';

export default {
  name: "MessageContainer",
  components: {
    ChatContainer, RoomNotSelected
  },
  props: {
    act: Object,
    topic: Object,
    detailClass: Object,
  },
  computed: {
    ...mapState('userModule', {
      userState: state => state.user
    }),
    ...mapState('roomModule', {
      roomID: state => state.activeRoom
    }),
    isRoomSelected: function () {
      return this.roomID;
    }
  },
  methods: {
    ...mapActions('roomModule', ['clearRoom'])
  },
  created() {
    // Clear room selection
    this.clearRoom();
  }
}
</script>
<style scoped>
.message-container {
  padding: 0;
}
</style>