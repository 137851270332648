import axios from 'axios'
import url from './api'

// function get_structured_exam_data(exam_data) {
//   const structured_exam_data = {
//     section_id: "",
//     is_random_question: false,
//     part_direction: "",
//     part_direction_media: "",
//     close_statement: "",
//     close_statement_media: "",
//     question: [],
//     status: 0,
//     code: "",
//     part: "",
//   }

//   Object.keys(structured_exam_data).forEach((key) => {
//     switch (key) {
//       case "part_direction":
//         structured_exam_data[key] = exam_data.part_direction.text;
//         break;
//       case "part_direction_media":
//         structured_exam_data[key] = exam_data.part_direction.with_media ? exam_data.part_direction.media.id : "";
//         break;
//       case "close_statement":
//         structured_exam_data[key] = exam_data.close_statement.text;
//         break;
//       case "close_statement_media":
//         structured_exam_data[key] = exam_data.close_statement.with_media ? exam_data.close_statement.media.id : "";
//         break;
//       case "question":
//         structured_exam_data[key] = exam_data.questions || exam_data.question;
//         break;
//       case "section_id":
//         structured_exam_data[key] = exam_data.section || exam_data.section_id;
//         break
//       case "status":
//         structured_exam_data[key] = exam_data.status.value || exam_data.status;
//         break;
//       default:
//         if (key in exam_data) structured_exam_data[key] = exam_data[key];
//         break;
//     }
//   });

//   return structured_exam_data
// }

const offline = false

const state = () => {
  return {
    exams: [],
    next_code: ''
  }
}

// For local data purpose
const mutations = {
  fetch(state, payload) {
    state.exams = payload.data
  },
  success: () => true
}

const getters = {
  detail({ state }, id) {
    return state.exams.find(exam => exam.id == id)
  },
  list: () => (params) => {
    let data = JSON.parse(localStorage.getItem('ytc_exams')) || []
    let offset = (params.page - 1) * params.page

    if (params.status) data = data.filter(data => data.status == params.status)

    return {
      limit: params.limit,
      list: data.slice().splice(offset, params.limit),
      page: params.page,
      total: data.length
    }
  }
}

const actions = {
  async list({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      params = Object.assign({ page: 1, limit: 10 }, params)

      if (offline) {
        try {
          resolve(url.success(getters.list(params)))
        } catch (error) {
          reject(error)
        }
      } else
        axios.get(url.exam_list, {
          params
        })
          .then(res => { commit('success'); resolve(res.data) })
          .catch(error => { reject(error) })
    })
  },
  async detail({ commit }, data_id) {
    return new Promise((resolve, reject) => {
      if (offline) {
        try {
          let datas = JSON.parse(localStorage.getItem('ytc_exams')) || []
          let data = datas.find(data => data.id == data_id)
          if (!data) {
            reject(url.error(data))
          }
          resolve(url.success(data))
        } catch (error) {
          reject(url.reject(error))
        }
      } else
        axios.get(url.exam_detail.replace('{id}', data_id))
          .then(res => { commit('success'); resolve(res.data) })
          .catch(error => { reject(error) })
    })
  }
}

export default { namespaced: true, state, mutations, getters, actions }