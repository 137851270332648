<template>
  <div>
    <!-- <div class="px-4 py-2">Mini Quiz. Temukan kata yang sudah ditentukan pada kalimat dibawah ini!</div> -->
    <v-divider></v-divider>

    <div class="pa-3" style="touch-action: none;">
      <!-- content -->

      <div class="mb-4">
        <v-row no-gutters>
          <v-col  cols="12" md="12">
            <!-- class="flex-grow-0"  -->
            <v-card flat>
              <v-card-title class="primary white--text justify-center">
                <v-icon left color="white">ri-file-list-line</v-icon>
                <span>KATA</span>
              </v-card-title>
  
              <div class="d-flex justify-space-between align-center grey">
                <v-btn icon small>
                  <v-icon @click="arrowLeft()">ri-arrow-left-s-line</v-icon>
                </v-btn>
                <div class="pa-4 overflow-auto d-flex flex-row wrapper-box" :style="{ height: `60px` }">
                  <div v-for="item in questions" :key="item">
                    <div
                      style="width:fit-content"
                      class="text-uppercase px-8 font-weight-bold text-center text-no-wrap"
                      :class="{ 'text-decoration-line-through': isAnswered(item) }">
                      {{ item }}
                    </div>
                  </div>
                </div>
                <v-btn icon small>
                  <v-icon @click="arrowRight()">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col cols="9" md="9">
            <!-- flex-grow-1 -->
            <div
              ref="stage"
              class="mr-4"
              style="position: relative; user-select: none"
              :style="{ cursor: selecting ? '' : '' }"
              @mouseup="handleStageMouseUp"
              @mousemove="handleStageMouseMove"
              @touchmove="handleStageMouseMove"
              @touchend="handleStageTouchEnd"
            >
              <!-- <div
                class="green lighten-3"
                style="position: absolute; top: 0; width: 1px; height: 100%"
                :style="{ left: pos.left + 'px' }"
              ></div>
              <div
                class="green lighten-3"
                style="position: absolute; left: 0; height: 1px; width: 100%"
                :style="{ top: pos.top + 'px' }"
              ></div> -->
              <div
                v-if="selecting"
                class="rounded-pill"
                :class="{ primary: !review, success: review }"
                style="position: absolute; height: 32px; transform-origin: 16px; opacity: 0.2"
                :style="selectingStyle"
              ></div>
  
              <div
                v-for="(style, index) in highlighter"
                :key="index"
                class="rounded-pill primary"
                style="position: absolute; height: 32px; transform-origin: 16px; opacity: 0.2"
                :style="style"
              ></div>
  
              <div class="d-flex" v-for="(hor, i1) in randomQuestion" :key="`hor-${i1}`">
                <div
                  v-for="(q, i2) in hor"
                  :key="`char-${i1}-${i2}`"
                  class="text-uppercase d-flex align-center justify-center"
                  :style="{ width: `${blockSize}px`, height: `${blockSize}px`, position: 'relative' }"
                >
                  <!-- :class="{ 'primary lighten-5': q.locked }" -->
                  <v-hover v-slot="{ hover }">
                    <span
                      :id="`char-${i1}-${i2}`"
                      class="d-flex rounded-circle d-flex align-center justify-center"
                      :class="{ primary: hover }"
                      @mousedown="handleQuestionMouseDown($event, [i1, i2])"
                      @mouseup.stop="handleQuestionMouseUp($event, [i1, i2])"
                      style="cursor: pointer; width: 30px; height: 30px"
                      @touchstart="handleQuestionMouseDown($event, [i1, i2])"
                      >
                      <!-- @touchend.stop="handleQuestionMouseUp($event, [i1, i2])" -->
                      {{ q.char }}
                    </span>
                  </v-hover>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- <div>
          DEBUG | selected: {{ currentAnswer }} | <br />jawaban: {{ savedAnswer.map((v) => v.answer).join(", ") }}
        </div> -->
      </div>

      <!-- timer -->
      <template>
        <div class="d-flex align-center mb-4">
          <v-icon left class="ritimer">ri-time-line</v-icon>
          <div class="mr-2">Sisa Waktu Berlangsung:</div>
          <div class="font-weight-bold">{{timer}}</div>
        </div>

        <div>
          <v-btn color="primary" elevation="0" class="rounded-pill" @click="onSaveAnswerData"> Submit </v-btn>
        </div>
      </template>
    </div>

    <v-snackbar top v-model="snackbar.state" :timeout="5000" color="primary" outlined>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data: () => ({
    // seq: 1,
    hintHeight: 0,
    questions: [],
    gameplayData: [],
    selectedAnswer: "",
    randomQuestion: [],
    randomAnswer: [],
    selecting: false,
    currentAnswer: [],
    savedAnswer: [],
    testArr : [],
    objectStyle: { x: 0, y: 0, width: 0, rotate: 0 },
    pos: { left: 0, top: 0 },
    snackbar: {
      state: false,
      text: "",
    },
    intervalTimer: null,
    timer: 0,
  }),
  computed: {
    highlighter() {
      return this.savedAnswer.map((v) => {
        const [coo1, coo2] = v.coo;
        const bound = this.$refs.stage.getBoundingClientRect();
        const obj1 = document.getElementById(`char-${coo1[0]}-${coo1[1]}`).getBoundingClientRect();
        const obj2 = document.getElementById(`char-${coo2[0]}-${coo2[1]}`).getBoundingClientRect();
        const wa = this.getWidthAngle(
          obj1.left - bound.top + 16,
          obj2.left - bound.top + 16,
          obj1.top - bound.top + 16,
          obj2.top - bound.top + 16
        );
        return {
          top: `${obj1.top - bound.top}px`,
          left: `${obj1.left - bound.left}px`,
          width: `${wa.width + 32}px`,
          transform: `rotate(${wa.rotate}deg)`,
        };
      });
    },
    selectingStyle() {
      const obj = this.objectStyle;
      return {
        width: `${obj.width}px`,
        left: `${obj.x}px`,
        top: `${obj.y}px`,
        transform: `rotate(${obj.rotate}deg)`,
      };
    },
    blockSize() {
      if (!this.$refs.stage) return 16;
      const bound = this.$refs.stage.getBoundingClientRect();
      const size = Math.floor(bound.width / 11);
      return size > 32 ? size : 32;
    },
  },
  mounted() {
    this.setQuestions();
  },
  unmounted() {
    clearInterval(this.intervalTimer)
  },
  methods: {
    arrowLeft() {
      let content = document.getElementsByClassName('wrapper-box')
      content[0].scrollLeft -= 250;
    },
    arrowRight() {
      let content = document.getElementsByClassName('wrapper-box')
      content[0].scrollLeft += 250;
    },
    handleCountdownTimer () {
      let setting = this.quizData.setting
      var timer = setting.time.duration * 60;
      let minutes;
      let seconds;

      this.intervalTimer = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timer =  minutes + ":" + seconds;

        // console.log(this.timer);

        if (--timer <= 0) {
          // timer = 60 * 10;
          clearInterval(this.intervalTimer)
          this.saveAnswerQuiz()
        }
        
      }, 1000);
    },
    isAnswered(answer) {
      return this.savedAnswer.some((v) => v.answer == answer);
    },
    createPoint(e) {
      const bound = this.$refs.stage.getBoundingClientRect();
      const obj = e.target.getBoundingClientRect();
      this.objectStyle.x = obj.left - bound.left;
      this.objectStyle.y = obj.top - bound.top;
      this.handleStageMouseMove(e);
    },
    handleStageMouseMove(e) {
      const bound = this.$refs.stage.getBoundingClientRect();
      const points = { x: e.touches[0].clientX - bound.left, y: e.touches[0].clientY - bound.top };
      this.pos.left = points.x;
      this.pos.top = points.y;
      if (!this.selecting) return;
      const obj = this.objectStyle;
      const wa = this.getWidthAngle(obj.x + 16, points.x, obj.y + 16, points.y);
      obj.width = wa.width + 32;
      obj.rotate = wa.rotate;
    },
    getWidthAngle(x1, x2, y1, y2) {
      const dy = y2 - y1;
      const dx = x2 - x1;
      const angleRad = Math.atan2(dy, dx);
      const angleDeg = (angleRad * 180) / Math.PI;
      const width = Math.pow(Math.pow(dy, 2) + Math.pow(dx, 2), 1 / 2);
      const rotate = (angleDeg + 360) % 360;
      return { width, rotate };
    },
    handleStageMouseUp() {
      this.setAnswer();
    },
    setAnswer(selected = null) {
      if (!this.selecting) return;
      this.selecting = false;
      this.objectStyle = { x: 0, y: 0, width: 0, rotate: 0 };

      if (!selected || (this.currentAnswer[0] != selected[0] && this.currentAnswer[1] != selected[1])) return;

      const coo = [this.currentAnswer, selected];
      this.currentAnswer = null;
      coo.sort((a, b) => (a[0] === b[0] ? (a[1] === b[0] ? 1 : a[1] - b[1]) : a[0] - b[0]));

      const ver = coo[0][0] === coo[1][0];
      const base = ver ? coo[0][0] : coo[0][1];
      const start = ver ? coo[0][1] : coo[0][0];
      const end = ver ? coo[1][1] : coo[1][0];

      this.selectedAnswer = "";
      for (let i = start; i <= end; i++)
        this.selectedAnswer += ver ? this.randomQuestion[base][i].char : this.randomQuestion[i][base].char;

      // console.log(coo, "|", "result:", this.selectedAnswer);
      if (this.savedAnswer.some((v) => v.answer == this.selectedAnswer)) return;
      if (this.questions.some((v) => v.toLocaleLowerCase() == this.selectedAnswer.toLocaleLowerCase()))
        this.savedAnswer.push({ answer: this.selectedAnswer, coo });

      if (this.savedAnswer.length == this.questions.length) {
        clearInterval(this.intervalTimer)
      }
    },
    setAnswerPreview() {
      let dataAnswer= []
      this.randomAnswer.forEach((item) => {
        dataAnswer.push({
          answer: item.answer,
          coo: [
            [item.coo[0], item.coo[1]],
            item.coo[3]
          ]
        })

        this.currentAnswer = [item.coo[0], item.coo[1]]
        // console.log(`char-${item.coo[0]}${item.coo[1]}`);
        this.setAnswer(item.coo[3])
      })

      // this.savedAnswer = dataAnswer
    },
    handleQuestionMouseDown(event, coo) {
      this.currentAnswer = coo;
      this.selecting = true;
      this.createPoint(event);
    },
    handleQuestionMouseUp(event, coo) {
      if (this.selecting) {
        this.setAnswer(coo);
      }
    },
    handleStageTouchEnd(e) {
      // console.log('handleStageTouchEnd',e);
      if (this.selecting) {
        const touch = e.changedTouches[0]
        const [x,y] = [touch.clientX, touch.clientY]
        const el = document.elementFromPoint(x,y)
        const coor = el.id.split('-')
        const coo = [Number(coor[1]), Number(coor[2])]
        this.setAnswer(coo);
      }
    },
    startQuiz() {
      const getCoo = (word, dir, reserved = [], reversed = false) => {
        const field = [];
        let last_coo = []
        for (let i in Array.from({ length: 11 }))
          for (let j in Array.from({ length: 11 })) field.push([parseInt(i), parseInt(j)]);
        const free = field.filter((v) => !reserved.some((v2) => v.toString() == v2.toString()));

        if (free.length == 0)
          if (reversed) return false;
          else return getCoo(word, !dir, [], true);

        const coo = free[Math.floor(Math.random() * free.length)];

        reserved.push(coo);

        const locked = word.split("").some((v, i) => {
          const x = coo[0] + (dir ? 0 : i);
          const y = coo[1] + (!dir ? 0 : i);
          const hor = this.randomQuestion[x];
          if (!hor) return true;
          const char = hor[y];
          if(word.substring(word.length - 1) == v || word.length == i) {
            last_coo = [x, y]
          }
          return !char || (char.locked && char.char != v);
        });
        if (locked) return getCoo(word, dir, reserved, reversed);

        return [...coo, reversed, last_coo];
      };

      this.randomAnswer = [];
      this.savedAnswer = [];
      this.randomQuestion = [];
      for (let i = 0; i < 11; i++) {
        const randomChar = Array.from({ length: 11 }, () => ({
          char: Math.floor(Math.random() * 26 + 10).toString(36),
          locked: false,
        }));
        this.randomQuestion.push(randomChar);
      }
      this.questions.forEach((word) => {
        const hor = Math.round(Math.random() * 1);
        const coo = getCoo(word, hor);
        if (!coo) return;
        this.randomAnswer.push({
          answer: word,
          coo,
        });
        const reversed = coo[2] ? !hor : hor;
        word.split("").forEach((char, index) => {
          const x = coo[0] + (reversed ? 0 : index);
          const y = coo[1] + (!reversed ? 0 : index);
          const pos = this.randomQuestion[x][y];
          pos.char = char;
          pos.locked = true;
        });
      });

      this.$nextTick().then(() => {
        const bound = this.$refs.stage.getBoundingClientRect();
        this.hintHeight = bound.height - 64;
      });

      // this.setAnswerPreview()
    },
    async setQuestions () {

      await this.axios
      .get(`/gameplay/v1/public/detail-multiple`, {
        params: {
          ids: this.quizData.gameplays.toString()
        }
      })
      .then((res) => res.data)
      .then(async (res) => {

        this.gameplayData = res.data

        this.questions = res.data[0].correct_answers

        this.startQuiz()
      })

      if(this.quizData.setting.time.enable) {
        this.handleCountdownTimer()
      }
    },
    onSaveAnswerData() {
      if(this.quizData.retake != null && this.quizData.retake >= this.quizData.setting.retake) {
        this.$emit('click:close-modal')
        clearInterval(this.intervalTimer)
        return  this.snackbar = {
          state: true,
          text: `Kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini sebanyak ${this.quizData.setting.retake} kali.`
        }
      }
      this.saveAnswerQuiz()
    },

    async saveAnswerQuiz() {

      let answersSaved = []
      let setting_timer = this.quizData.setting.time.duration * 60;
      let last_duration_minutes = 0
      let minutes = 0
      let seconds = 0

      if(this.quizData.setting.time.enable){
        last_duration_minutes = Number(this.timer.split(':')[0]) + Number(this.timer.split(':')[1] / 60)
  
        minutes = parseInt(setting_timer / 60, 10) - last_duration_minutes
  
        seconds = parseInt(minutes * 60, 10);
      }

      this.savedAnswer.forEach((item) => {
        answersSaved.push(item.answer)
      })

      let data = {
        member_redeem_id: this.$route.params.id,
        topic_id: this.quizData.topic_id,
        activity_id: this.quizData.activity_id,
        answer_duration: seconds,
        answer: [
          {
            gameplay_id: this.gameplayData[0].id,
            answer: answersSaved
          }
        ]
      }

      await this.axios.post(`/gameplay/v1/answer`, data)
      .then((response) => {
        let res = response.data
        this.snackbar = {
          state: true,
          text: "Jawaban berhasil dikirimkan"
        }
        clearInterval(this.intervalTimer)

        this.$emit('click:submit', res.data)
      }).catch((error) => {
        let message = ""
        if(error instanceof Error) {
          message = error.message;
          if (error.response?.data?.message) {
            message = error.response.data.message;
          }
        }

        this.snackbar = {
          state: true,
          text: message
        }

        if(message == 'kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini') {
          setTimeout(() => {
            this.$emit('click:close-modal')
          }, 2000);
        }
      })
    }
  },
};
</script>

<style>