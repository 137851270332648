var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"transparent px-2",attrs:{"elevation":"0"}},[_c('vue-html2pdf',{ref:"certifPeserta",attrs:{"enable-download":true,"preview-modal":false,"show-layout":true,"pdf-quality":2,"filename":_vm.certificateName,"pdf-orientation":"landscape","manual-pagination":true,"pdf-content-width":"100%"},on:{"hasDownloaded":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[[_c('section',{staticClass:"pdf-item",style:({
            position: 'relative',
            backgroundImage: _vm.customBg.front,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })},[_c('div',{staticStyle:{"position":"relative","top":"38.5%","color":"#000","font-family":"'PT Sans', sans-serif","font-style":"normal","font-weight":"900","font-size":"28px","line-height":"auto","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.data.program_name)+" ")]),_c('div',{staticStyle:{"position":"absolute","width":"100%","top":"52%","color":"#000","font-family":"'Great Vibes', cursive","font-style":"normal","font-weight":"900","font-size":"52px","line-height":"auto","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.data.user_name)+" ")]),_c('div',{staticStyle:{"position":"relative","top":"60%","color":"#000","font-family":"'PT Sans', sans-serif","font-size":"18px","text-align":"center"}},[_vm._v(" Atas keberhasilan menyelesaikan program selama 15 jam ")]),(_vm.data.feedback_score > 80)?_c('div',{staticStyle:{"top":"65%","position":"relative","color":"#000","font-family":"'PT Sans', sans-serif","font-size":"18px","text-align":"center"}},[_c('strong',[_vm._v(" LULUS ")]),_vm._v(" dengan predikat "),_c('strong',[_vm._v(" \"SANGAT BAIK\" ")])]):_vm._e(),_c('div',{staticStyle:{"position":"relative","color":"#000","font-family":"'PT Sans', sans-serif","font-size":"22px","font-weight":"bold","text-align":"center"},style:({
              top: _vm.data.feedback_score > 80 ? '454px' : '478px',
            })},[_vm._v(" Sidoarjo, "+_vm._s(_vm.currentDateTime(_vm.data.end_test_at))+" ")]),_c('div',{staticStyle:{"font-family":"'PT Sans', sans-serif","font-style":"normal","font-weight":"bold","font-size":"16px","line-height":"14px","text-align":"center","position":"absolute","left":"23%","bottom":"10%","display":"inline-block"}},[_c('vue-qrcode',{attrs:{"value":_vm.data.certificate_url,"options":{ color: { dark: '#4B4B4B' }, width: 100 }}}),_c('div',[_vm._v(_vm._s(_vm.data.license))])],1)]),_c('section',{staticClass:"pdf-item",style:({
            position: 'relative',
            backgroundImage: _vm.customBg.back,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })},[(!_vm.terkustomkustom.includes(_vm.data.program_name.toLowerCase()))?[_c('div',{staticStyle:{"position":"absolute","width":"100%","top":"29%","color":"#000","font-family":"'PT Sans', sans-serif","font-style":"normal","font-weight":"900","font-size":"28px","line-height":"auto","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.data.program_name)+" ")]),_c('div',{staticStyle:{"position":"absolute","width":"100%","top":"45%","color":"#000","font-family":"'PT Sans', sans-serif","font-style":"normal","font-size":"20px","line-height":"auto","text-align":"center"}},[_c('div',{staticStyle:{"display":"inline-block","width":"67%"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Kompetensi")])])]),_c('tbody',_vm._l((_vm.data.competence),function(item){return _c('tr',{key:item},[_c('td',[_vm._v(_vm._s(item))])])}),0)])])])]:_vm._e()],2)]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }