<template>
  <div>
    <!-- <div class="px-4 py-2">Mini Quiz. Jodohkan kalimat/gambar dibawah ini!</div> -->
    <v-divider></v-divider>
    <div class="pa-4">
      <!-- navigation -->
      <div class="d-flex justify-center mb-3 text-center">
        <div class="d-flex align-center">
          <div class="mx-4 font-weight-bold">
            Soal
            <span class="primary--text font-weight-black fs-20">{{ gameplay.current }}</span>
            dari
            {{ dataGameplay.length }}
          </div>
        </div>
      </div>

      <div>
        Double klik pada titik sebelah kiri untuk menghapus jawaban
      </div>

      <!-- content -->
      <div class="mb-4"  style="touch-action: none;">
        <div
          ref="stage"
          style="position: relative; user-select: none"
          @mousemove="handleStageMouseMove"
          @mouseup="handleStageMouseUp"
          @touchmove="handleStageMouseMove"
          @touchend="handleStageTouchEnd"
          :style="{ cursor: selecting ? 'pointer' : '' }"
        >
          <div
            v-if="selecting"
            class="rounded-pill red darken-1"
            style="position: absolute; height: 8px; transform-origin: 4px center"
            :style="selectingStyle"
          ></div>

          <v-hover v-for="(line, index) in pairedLine" v-slot="{ hover }" :key="`line-${index}`">
            <div
              class="rounded-pill red darken-1"
              :class="{ 'elevation-2': hover }"
              :style="line"
              style="position: absolute; height: 8px; transform-origin: 4px center; cursor: pointer"
              @dblclick="handleLineDbl(index)"
            ></div>
          </v-hover>

          <div
            v-for="index in questions.length"
            :key="`q-${index}`"
            class="d-flex pa-3 justify-space-between align-center">

          <div 
            v-if="randomQuestion[index - 1] != undefined" 
            :style="$vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' ? 'width: 8rem;' : 'width: 4rem;'" >
            <v-img 
              v-if="questions[randomQuestion[index - 1]].question.includes('https')" 
              width="100" height="100" contain :src="questions[randomQuestion[index - 1]].question" 
            />
            <div v-else>{{ questions[randomQuestion[index - 1]].question }}</div>
          </div>
            
            <!-- <div v-if="randomQuestion[index - 1] != undefined" class="d-flex align-center">
              <v-img width="100" height="100" contain :src="questions[randomQuestion[index - 1]].question" />
              <v-hover v-slot="{ hover }">
                <v-avatar
                  :id="`question-${index}`"
                  size="16"
                  color="grey darken-3 ma-4"
                  :class="{ 'elevation-0': !hover, 'elevation-2': hover }"
                  @mousedown="handleQuestionMouseDown($event, randomQuestion[index - 1])"
                  @mouseup.stop="handleQuestionMouseUp($event, randomQuestion[index - 1])"
                  style="cursor: pointer"
                ></v-avatar>
              </v-hover>
            </div> -->

            <div class="d-flex justify-space-between" style="flex: 1;">
              <v-hover v-slot="{ hover }">
                <v-avatar
                  :id="`question-${index}`"
                  size="22"
                  color="grey darken-1 ma-4"
                  :class="{ 'elevation-0': !hover, 'elevation-2': hover }"
                  @dblclick="handleDoubleClickMouse(randomQuestion[index - 1])"
                  @mousedown="handleQuestionMouseDown($event, randomQuestion[index - 1])"
                  @mouseup.stop="handleQuestionMouseUp($event, randomQuestion[index - 1])"
                  
                  @touchstart="handleQuestionMouseDown($event, randomQuestion[index - 1])"
                  style="cursor: pointer"
                  ></v-avatar>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-avatar
                  :id="`answer-${index}`"
                  size="22"
                  color="grey darken-1 ma-4"
                  :class="{ 'elevation-0': !hover, 'elevation-2': hover }"
                  @mousedown="handleAnswerMouseDown($event, randomAnswer[index - 1])"
                  @mouseup.stop="handleAnswerMouseUp($event, randomAnswer[index - 1])"

                  @touchstart="handleQuestionMouseDown($event, randomAnswer[index - 1])"
                  style="cursor: pointer"
                ></v-avatar>
              </v-hover>
            </div>

            <div 
              v-if="randomAnswer[index - 1] != undefined" 
              :style="$vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' ? 'width: 8rem;' : 'width: 3rem;'">
              <v-img 
                v-if="questions[randomAnswer[index - 1]].answer.includes('https')" 
                width="100" height="100" contain :src="questions[randomAnswer[index - 1]].answer" />
              <div v-else>{{ questions[randomAnswer[index - 1]].answer }}</div>
            </div>

            <!-- <div v-if="randomAnswer[index - 1] != undefined" class="d-flex align-center">
              <v-hover v-slot="{ hover }">
                <v-avatar
                  :id="`answer-${index}`"
                  size="16"
                  color="grey darken-3 ma-4"
                  :class="{ 'elevation-0': !hover, 'elevation-2': hover }"
                  @mousedown="handleAnswerMouseDown($event, randomAnswer[index - 1])"
                  @mouseup.stop="handleAnswerMouseUp($event, randomAnswer[index - 1])"
                  style="cursor: pointer"
                ></v-avatar>
              </v-hover>
              <v-alert class="ma-0">
                {{ questions[randomAnswer[index - 1]].answer }}
              </v-alert>
            </div> -->
          </div>
        </div>

        <!-- <div class="mt-4">
          <div>debug</div>
          <div class="mb-2">
            Is selecting: {{ selecting }} | Current question index: {{ currentQuestion }} | Current answer index:
            {{ currentAnswer }}
          </div>
          <div v-for="(answer, index) in savedAnswer" :key="index">
            <div>Question index {{ answer.question }} &lt;==&gt; {{ answer.answer }} Answer index</div>
          </div>
        </div> -->
      </div>

      <!-- timer -->
      <template>
        <div class="d-flex align-center mb-4" v-if="quizData.setting.time.enable">
          <v-icon left class="ritimer">ri-time-line</v-icon>
          <div class="mr-2">Sisa Waktu Berlangsung:</div>
          <div class="font-weight-bold">{{ timer }}</div>
        </div>

        <div class="text-right">
          <v-btn 
            v-if="gameplay.current == dataGameplay.length"
            color="primary" elevation="0" class="rounded-pill" 
            @click="onSaveAnswerData('save')"> 
            Submit Jawaban 
          </v-btn>
          <v-btn 
            v-else
            color="primary" elevation="0" class="rounded-pill font-weight-bold" 
            outlined
            @click="onSaveAnswerData('next')"> 
            Selanjutnya 
            <v-icon>$next</v-icon>
          </v-btn>
        </div>
      </template>
    </div>

    <v-snackbar top v-model="snackbar.state" :timeout="5000" color="primary" outlined>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
export default {
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data: () => ({
    seq: 1,
    questions: [
      // {
      //   question: "https://pngfre.com/wp-content/uploads/apple-43-1024x1015.png",
      //   answer: "Apple",
      // },
      // {
      //   question: "https://upload.wikimedia.org/wikipedia/commons/9/96/Strawberry-icon-1000px.png",
      //   answer: "Strawberry",
      // },
      // {
      //   question: "https://pngfre.com/wp-content/uploads/orange-png-from-pngfre-1-1024x708.png",
      //   answer: "Orange",
      // },
    ],
    dataGameplay: [],
    randomQuestion: [],
    randomAnswer: [],
    selecting: false,
    currentAnswer: null,
    currentQuestion: null,
    savedAnswer: [],
    answerData: [],
    objectStyle: { x: 0, y: 0, width: 0, rotate: 0 },
    gameplay: {},
    snackbar: {
      state: false,
      text: "",
    },
    intervalTimer: null,
    timer: 0,
    counter: 0,
  }),
  computed: {
    pairedLine() {
      return this.savedAnswer.reduce((car, cur) => {
        if (this.selecting && (cur.question == this.currentQuestion || cur.answer == this.currentAnswer)) return car;
        if (!this.$refs.stage) return car;
        const bound = this.$refs.stage.getBoundingClientRect();
        const obj1 = document
          .getElementById(`question-${this.randomQuestion.findIndex((v) => v == cur.question) + 1}`)
          .getBoundingClientRect();
        const obj2 = document
          .getElementById(`answer-${this.randomAnswer.findIndex((v) => v == cur.answer) + 1}`)
          .getBoundingClientRect();
        const wa = this.getWidthAngle(
          obj2.left - bound.top + 4,
          obj1.left - bound.top + 4,
          obj2.top - bound.top + 4,
          obj1.top - bound.top + 4
        );
        if (wa.rotate >= 90 && wa.rotate < 270) wa.rotate += 180;
        car.push({
          top: `${obj1.top - bound.top + 4}px`,
          left: `${obj1.left - bound.left + 4}px`,
          width: `${wa.width}px`,
          transform: `rotate(${wa.rotate}deg)`,
        });
        return car;
      }, []);
    },
    selectingStyle() {
      const obj = this.objectStyle;
      return {
        width: `${obj.width}px`,
        left: `${obj.x}px`,
        top: `${obj.y}px`,
        transform: `rotate(${obj.rotate}deg)`,
      };
    },
  },
  mounted() {
    this.setQuestions()
  },
  unmounted() {
    clearInterval(this.intervalTimer)
  },
  methods: {
    handleCountdownTimer () {
      let setting = this.quizData.setting
      var timer = setting.time.duration * 60;
      let minutes;
      let seconds;

      this.intervalTimer = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timer =  minutes + ":" + seconds;

        // console.log(this.timer);

        if (--timer <= 0) {
          // timer = 60 * 10;
          clearInterval(this.intervalTimer)
          this.saveAnswerQuiz()
        }
        
      }, 1000);
    },

    handleLineDbl(index) {
      this.savedAnswer.splice(index, 1);
    },
    createPoint(e) {
      const bound = this.$refs.stage.getBoundingClientRect();
      const selected = this.savedAnswer.find(
        (v) => v.question === this.currentQuestion || v.answer === this.currentAnswer
      );
      const elementId = !selected
        ? ""
        : selected.question === this.currentQuestion
        ? `answer-${this.randomAnswer.findIndex((v) => v === selected.answer) + 1}`
        : `question-${this.randomQuestion.findIndex((v) => v === selected.question) + 1}`;
      const obj = selected
        ? document.getElementById(elementId).getBoundingClientRect()
        : e.target.getBoundingClientRect();

      this.objectStyle.x = obj.left - bound.left + 4;
      this.objectStyle.y = obj.top - bound.top + 4;
      this.handleStageMouseMove(e);
    },
    handleStageMouseMove(e) {
      let points = undefined;
      if (!this.selecting) return;
      const bound = this.$refs.stage.getBoundingClientRect();
      points = { x: e.x - bound.left, y: e.y - bound.top };
      if(this.$vuetify.breakpoint.name == 'xs') {
        if(e.touches == undefined) {
          this.selecting = false
          this.handleDoubleClickMouse(this.currentQuestion)
        }else {
          points = { x: e.touches[0].clientX - bound.left, y: e.touches[0].clientY - bound.top };
        }
      }
      const obj = this.objectStyle;
      const wa = this.getWidthAngle(obj.x, points.x, obj.y, points.y);
      obj.width = wa.width;
      obj.rotate = wa.rotate;
    },
    getWidthAngle(x1, x2, y1, y2) {
      const dy = y2 - y1;
      const dx = x2 - x1;
      const angleRad = Math.atan2(dy, dx);
      const angleDeg = (angleRad * 180) / Math.PI;
      const width = Math.pow(Math.pow(dy, 2) + Math.pow(dx, 2), 1 / 2);
      const rotate = (angleDeg + 360) % 360;
      return { width, rotate };
    },
    handleStageMouseUp() {
      this.setAnswer();
    },
    setAnswer(question = null, answer = null) {
      const savedAnswer = this.savedAnswer;
      const selectedQuestion = savedAnswer.findIndex((v) => v.question == this.currentQuestion);
      const selectedAnswer = savedAnswer.findIndex((v) => v.answer == this.currentAnswer);
      const replacedQuestion = savedAnswer.findIndex((v) => v.question == question);
      const replacedAnswer = savedAnswer.findIndex((v) => v.answer == answer);

      if (selectedQuestion != -1) {
        if (question == null) savedAnswer.splice(selectedQuestion, 1);
        else if (this.currentQuestion != question) {
          if (savedAnswer[selectedQuestion]) savedAnswer[selectedQuestion].question = question;
          // if (savedAnswer[replacedQuestion]) savedAnswer[replacedQuestion].question = this.currentQuestion;
        } else if (this.currentAnswer == null && answer != null) savedAnswer.splice(selectedQuestion, 1);
      } else if (selectedAnswer != -1) {
        if (answer == null) savedAnswer.splice(selectedAnswer, 1);
        else if (this.currentAnswer != answer) {
          if (savedAnswer[selectedAnswer]) savedAnswer[selectedAnswer].answer = answer;
          // if (savedAnswer[replacedAnswer]) savedAnswer[replacedAnswer].answer = this.currentAnswer;
        } else if (this.currentQuestion == null && question != null) savedAnswer.splice(selectedAnswer, 1);
      } else if (replacedQuestion == -1 && replacedAnswer == -1 && question != null && answer != null) {
        savedAnswer.push({ question, answer });
      }

      this.currentAnswer = null;
      this.currentQuestion = null;
      this.selecting = false;
      this.objectStyle = { x: 0, y: 0, width: 0, rotate: 0 };

      // console.log('savedAnswer',savedAnswer);
    },
    handleDoubleClickMouse(question) {
      const savedAnswer = this.savedAnswer;
      const replacedQuestion = savedAnswer.findIndex((v) => v.question == question);

      this.savedAnswer.splice(replacedQuestion, 1)

    },
    handleQuestionMouseDown(event, question) {
      this.currentQuestion = question;
      this.selecting = true;
      this.createPoint(event);
    },
    handleQuestionMouseUp(event, question) {
      if (this.selecting) {
        this.setAnswer(question, this.currentAnswer);
      }
    },
    handleAnswerMouseDown(event, answer) {
      this.currentAnswer = answer;
      this.selecting = true;
      this.createPoint(event);
    },
    handleAnswerMouseUp(event, answer) {
      if (this.selecting) {
        this.setAnswer(this.currentQuestion, answer);
      }
    },
    handleStageTouchEnd(e) {
      const touch = e.changedTouches[0]
      const [x,y] = [touch.clientX, touch.clientY]
      const el = document.elementFromPoint(x,y)
      const index = el.id.split('-')
      const answer = this.randomAnswer[index[1] - 1]
      
      if (this.selecting) {
        this.setAnswer(this.currentQuestion, answer);
      }
    },
    startQuiz() {
      this.savedAnswer = [];
      this.randomQuestion = [...Array(this.questions.length).keys()]
      // .sort(() => Math.random() - 0.5);
      this.randomAnswer = [...Array(this.questions.length).keys()].sort(() => Math.random() - 0.2);

      if(this.compareArrays(this.randomQuestion, this.randomAnswer)) {
        this.startQuiz() 
      }

      // console.log('Kiri',this.randomQuestion);
      // console.log('Kanan',this.randomAnswer);
    },

    compareArrays(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },

    async setQuestions () {

      await this.axios
      .get(`/gameplay/v1/public/detail-multiple`, {
        params: {
          ids: this.quizData.gameplays.toString()
        }
      })
      .then((res) => res.data)
      .then(async (res) => {

        this.$set(this.gameplay, "current", 1);
        // this.$set(this.gameplay, "list_question", res.data);

        this.dataGameplay = res.data
      })

      if(this.quizData.setting.time.enable) {
        this.handleCountdownTimer()
      }

      this.onUpdateData()
    },
    onUpdateData() {
      let data = []

      this.dataGameplay[this.gameplay.current - 1].options.forEach((item, index) => {
        data.push({
          question: item.text ? item.text : item.image,
          answer: this.dataGameplay[this.gameplay.current - 1].correct_answers[index]
        })
      })

      this.questions = data

      this.startQuiz()
    },

    onSaveAnswerData(type) {
      let answered = []

      this.savedAnswer.sort((a, b) => {
        return a.question - b.question
      })

      this.savedAnswer.forEach((aswr) => {
        answered.push(this.dataGameplay[this.gameplay.current - 1].correct_answers[aswr.answer])
      })

      // console.log('answered',answered);

      this.answerData.push({
        gameplay_id: this.dataGameplay[this.gameplay.current - 1].id,
        answer: answered
      })

      // console.log('this.answerData',this.answerData);

      
      if(type == 'next') {
        this.gameplay.current++
        this.onUpdateData()
      }else {

        if(this.quizData.retake != null && this.quizData.retake >= this.quizData.setting.retake) {
          this.$emit('click:close-modal')
          clearInterval(this.intervalTimer)
          return  this.snackbar = {
            state: true,
            text: `Kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini sebanyak ${this.quizData.setting.retake} kali.`
          }
        }
        this.saveAnswerQuiz()
      }
    },
    async saveAnswerQuiz() {
      
      let setting_timer = this.quizData.setting.time.duration * 60;
      let last_duration_minutes = 0
      let minutes = 0
      let seconds = 0

      if(this.quizData.setting.time.enable){
        last_duration_minutes = Number(this.timer.split(':')[0]) + Number(this.timer.split(':')[1] / 60)
  
        minutes = parseInt(setting_timer / 60, 10) - last_duration_minutes
  
        seconds = parseInt(minutes * 60, 10);
      }


      let data = {
        member_redeem_id: this.$route.params.id,
        topic_id: this.quizData.topic_id,
        activity_id: this.quizData.activity_id,
        answer_duration: seconds,
        answer: this.answerData
      }

      
      await this.axios.post(`/gameplay/v1/answer`, data).then((response) => {
        let res = response.data
        this.snackbar = {
          state: true,
          text: "Jawaban berhasil dikirimkan"
        }
        
        clearInterval(this.intervalTimer)
        this.$emit('click:submit', res.data)
      }).catch((error) => {
        clearInterval(this.intervalTimer)
        let message = ""
        if(error instanceof Error) {
          message = error.message;
          if (error.response?.data?.message) {
            message = error.response.data.message;
          }
        }

        this.snackbar = {
          state: true,
          text: message
        }

        if(message == 'kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini') {     
          setTimeout(() => {
            this.$emit('click:close-modal')
          }, 2000);
        }
      })
    },
  },
};
</script>
