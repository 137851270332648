<template>
  <div v-on:click="selectRoomHandler" :class="`${status} room`">
    <v-list-item two-line class="px-3">
      <v-badge
          bordered
          bottom
          :color="statusColor"
          dot
          offset-x="10"
          offset-y="10"
          class="mr-2" >
          <v-avatar size="38">
            <!-- <v-icon v-if="!room.isPrivate" dark>mdi-account-circle</v-icon> -->
              <img :src="`https://placehold.co/600x600/34495e/ecf0f1?text=${room.trainer.name[0].toUpperCase()}`" :alt="`${room.trainer.name}`" />
          </v-avatar>
      </v-badge>

      <v-list-item-content>
        <v-list-item-title class="subtitle-2 font-weight-bold text-capitalize">{{ room.trainer.name }}</v-list-item-title>
        <!-- <v-list-item-title class="caption">{{ room.trainer.email }}</v-list-item-title> -->
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "Room",
  props: ["room", "active", "online"],
  computed: {
    ...mapState('roomModule', {
      roomID: state => state.activeRoom
    }),
    status() {
      return this.active ? "active" : "";
    },
    statusColor() {
      return this.online ? "green" : "red";
    }
  },
  methods: {
    ...mapActions('roomModule', ['selectRoom', 'clearRoom']),
    selectRoomHandler: function () {
      if (this.roomID != this.room.id) {
        // Clear the room selection first
        this.clearRoom();

        // Select the room again
        var data = {
          room: this.room.id,
          program_id: this.room.program_id,
          trainer_id: this.room.trainer.id,
        };

        this.selectRoom(data);
      }
      
      this.$emit('click:selectedRoom', this.room)
    }
  }
}
</script>
<style scoped>
.room {
  cursor: pointer;
}

.room.active,
.room:hover {
  background-color: #eeeeee;
}
</style>