<template>
  <div>
    <template v-if="!complete">
      <v-card-title class="d-flex justify-space-between pa-0" style="font-size: 15px;">
        <div class="px-4 py-2">{{ quizData.introduction }}</div>
        <!-- <div class="px-4 py-2" v-if="type == 'multiple_choice'">Mini Quiz. Jawab pertanyaan di bawah ini!</div> -->
        <!-- <div class="px-4 py-2" v-if="type == 'jigsaw'">Mini Quiz. Jawab pertanyaan di bawah ini!</div> -->
        <!-- <div class="px-4 py-2" v-if="type == 'matching_word'">Mini Quiz. Jodohkan kalimat/gambar di bawah ini!</div> -->
        <!-- <div class="px-4 py-2" v-if="type == 'finding_word'">Mini Quiz. Temukan kata yang sudah ditentukan pada kalimat di bawah ini!</div> -->
      </v-card-title>
      <!-- {{ quizData }} -->
      <quiz-choice v-if="type == 'multiple_choice'" :quizData="quizData" :review="review" @click:back-to-complete="backToComplete" @click:submit="submitQuiz" @click:close-modal="$emit('click:close'); complete = false"/>
      <quiz-jigsaw v-else-if="type == 'jigsaw'" :quizData="quizData" :review="review" @click:back-to-complete="backToComplete" @click:submit="submitQuiz"  @click:close-modal="$emit('click:close'); complete = false"/>
      <quiz-pairing v-else-if="type == 'matching_word'" :quizData="quizData" :review="review" @click:back-to-complete="backToComplete" @click:submit="submitQuiz"  @click:close-modal="$emit('click:close'); complete = false"/>
      <quiz-word-search v-else-if="type == 'finding_word' && $vuetify.breakpoint.name != 'xs'" :quizData="quizData" :review="review" @click:back-to-complete="backToComplete" @click:submit="submitQuiz" @click:close-modal="$emit('click:close'); complete = false"/>
      <quiz-word-search-mobile v-else-if="type == 'finding_word' && $vuetify.breakpoint.name == 'xs'" :quizData="quizData" :review="review" @click:back-to-complete="backToComplete" @click:submit="submitQuiz" @click:close-modal="$emit('click:close'); complete = false"/>
    </template>
    <template v-else>
      <v-row class="py-3" justify="center">
        <v-col cols="8" class="d-flex justify-center">
          <v-icon class="d-block" color="grey lighten-2" size="60"
            >ri-file-edit-fill</v-icon
          >
        </v-col>
        <v-col cols="10">
          <table style="width: 100%">
            <tbody>
              <tr v-if="responseAnswer.total_correct">
                <td class="pa-2">Jumlah Benar</td>
                <td class="pa-2 text-right">{{ responseAnswer.total_correct }}</td>
              </tr>
              <tr>
                <td class="pa-2">Kesempatan mengulang</td>
                <td class="pa-2 text-right">{{ quizData.setting.retake == -1 ||  quizData.setting.retake == null ? "Tanpa Batas" : quizData.setting.retake +"x" }}</td>
              </tr>
              <tr v-if="quizData.setting.minimum_score != null">
                <td class="pa-2">Nilai minimal</td>
                <td class="pa-2 text-right">{{ quizData.setting.minimum_score }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="8" class="text-center" v-show="quizData.setting.show_score">
          <div class="text-center font-weight-bold">Nilai</div>
          <div class="text-center primary--text mt-3">
            <span class="text-h2 font-weight-bold">
              {{ responseActivity.score }}
              <span class="text-h4 font-weight-bold" v-if="quizData.setting.minimum_score != null">{{ "/ " + quizData.setting.minimum_score }}</span>
            </span>
          </div>
        </v-col>

        <v-col md="6" sm="12">
          <v-btn class="mb-4" rounded block color="primary" outlined elevation="0" large @click="repeat" :disabled="quizData.retake >= quizData.setting.retake">
            Ulangi Quiz
          </v-btn>
          <v-btn v-show="(type == 'multiple_choice' || type == 'jigsaw') && quizData.setting.show_explanation" class="mb-4" rounded block color="primary" elevation="0" large @click="review = true; complete = false">
            Lihat Pembahasan
          </v-btn>
          <v-btn v-if="showClose" class="mb-4" rounded block color="primary" elevation="0" large @click="onClickNextActivity()">
            Klik Aktivitas Selanjutnya
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import QuizChoice from "./pop-quiz/QuizChoice.vue";
import QuizJigsaw from "./pop-quiz/QuizJigsaw.vue";
import QuizPairing from "./pop-quiz/QuizPairing.vue";
import QuizWordSearch from "./pop-quiz/QuizWordSearch.vue";
import QuizWordSearchMobile from "./pop-quiz/QuizWordSearchMobile.vue";

export default {
  components: {
    QuizChoice,
    QuizJigsaw,
    QuizPairing,
    QuizWordSearch,
    QuizWordSearchMobile
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    quizData: {
      type: Object,
      default() {
        return {}
      }
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    complete: false,
    review: false,
    responseActivity: {},
    responseAnswer: {}
  }),
  mounted() {
    this.validateShowReview()
    // console.log("🚀 ~ file: PopQuiz.vue:109 ~ created ~ this.quizData.setReview:", this.quizData.setReview)
    // if (this.quizData.setReview) {
    //   if(this.validateShowReview()) {
    //     this.review = true
    //     this.complete = true
  
    //     this.getDetailActivity()
    //   }else {
    //     this.review = false
    //     this.complete = false
    //   }
    //   // console.log("🚀 ~ file: PopQuiz.vue:110 ~ created ~ setReview:", true);
    // }else {
    //   // console.log("🚀 ~ file: PopQuiz.vue:122 ~ created ~ setReview:", false);
    //   this.review = false
    //   this.complete = false
    // }
  },
  computed: { 
    showClose() {
      let isValid = false
      if(this.quizData.retake >= this.quizData.setting.retake) {
        isValid = true
      }else if (this.quizData.setting.retake == null) {
        isValid = true
      }else if (this.quizData.setting.retake == -1) {
        isValid = true
      }else if (this.quizData.setting.retake == 0) {
        isValid = true
      }else if (this.quizData.setting.show_score) {
        if(Object.keys(this.responseActivity).length > 0) {
          // jika sudah ada response activity
          if(Number(this.responseActivity.score) >= Number(this.quizData.setting.minimum_score)) {
            isValid = true
          }
        }else {
          // jika belum ada response activity
          if(Number(this.quizData.score) >= Number(this.quizData.setting.minimum_score)) {
            isValid = true
          }
        }
      }else if(!this.quizData.setting.show_score) {
        isValid = true
      }
      return isValid
      // return (this.quizData.retake == this.quizData.setting.retake) || this.quizData.setting.retake == null || this.quizData.setting.retake == -1 || this.quizData.setting.retake == 0 || (this.quizData.setting.show_score ? Number(this.responseAnswer.total_point) >= Number(this.quizData.setting.minimum_score) : true)
    }
  },
  methods: {

    validateShowReview() {
      let isValidReview = false
      
      if(this.quizData.retake != null) {
        if(this.quizData.retake >= this.quizData.setting.retake) {
          isValidReview = true
        }
      }
      
      if(this.quizData.setting.minimum_score != null) {
        if(Number(this.quizData.score) >= Number(this.quizData.setting.minimum_score)) {
          isValidReview = true
        }
      }
      
      if(this.quizData.score != null && this.quizData.score > 0) {
        isValidReview = true
      }

      if(this.quizData.score == null) {
        isValidReview = false
      }

      if (isValidReview) {
        this.review = true
        this.complete = true

        this.getDetailActivity()
      }else {
        this.review = false
        this.complete = false
      }
      
      // console.log("🚀 ~ file: PopQuiz.vue:128 ~ validateShowReview ~ text:", text)
      // console.log("🚀 ~ file: PopQuiz.vue:148 ~ validateShowReview ~ isValidReview:", isValidReview)

      // return isValidReview
    },

    submitQuiz(data) {
      this.complete = true
      this.responseAnswer = data
      this.getDetailActivity()
      if(this.quizData.retake == null) {
        this.$set(this.quizData, 'retake', 0)
      }else {
        this.$set(this.quizData, 'retake', Number(this.quizData.retake) + 1)
      }
      
      this.$set(this.quizData, 'score', data.total_point)
    },

    // get detail activity
    getDetailActivity() {
      this.axios.get(`/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.quizData.topic_id}/${this.quizData.activity_id}`)
        .then((response) => {
          let res = response.data
          // set activity
          this.responseActivity = res.data.quiz
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onClickNextActivity() {
      this.review = false
      this.complete = false
      this.$emit('click:close'); 
    },
    repeat() {
      this.complete = false
      this.review = false
    },
    backToComplete() {
      this.complete = true
      this.review = false
    }
  }
};
</script>