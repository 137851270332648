import axios from "axios";
import url from "./api";

// const offline = true

const state = () => ({
  user: null,
  status: "",
});

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  },
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {
  async login({ commit }, user) {
    user.access_key = process.env.VUE_APP_ACCESS_KEY;
    user.provider = "whatsapp";

    return axios
      .post(url.user_login, user, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data)
      .then((res) => {
          let user = res.data;
          let token = `Bearer ${user.token}`;

          axios.defaults.headers.common["Authorization"] = token;
          localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
          commit("SET_USER", user);
          // return "success";
          return res.data;
      });
  },

  async sso_login({ commit }, user) {
    return axios
      .post(
        url.user_auto_login,
        {
          access_key: process.env.VUE_APP_ACCESS_KEY,
          email: user.email,
          fullname: user.fullname,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data)
      .then((res) => {
        let user = res.data;
        let token = `Bearer ${user.token}`;

        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
        commit("SET_USER", user);
        return "success";
      });
  },

  async login_google({ commit }, id_token) {
    return axios
      .post(
        url.user_login,
        {
          provider: "google",
          access_key: process.env.VUE_APP_ACCESS_KEY,
          access_token: id_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data)
      .then((res) => {
        let user = res.data;
        let token = `Bearer ${user.token}`;

        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
        commit("SET_USER", user);
        return res.data;
      });
  },

  async login_facebook({ commit }, id_token) {
    return axios
      .post(
        url.user_login,
        {
          access_key: process.env.VUE_APP_ACCESS_KEY,
          email: "",
          password: "",
          provider: "facebook",
          access_token: id_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data)
      .then((res) => {
        let user = res.data;
        let token = `Bearer ${user.token}`;

        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
        commit("SET_USER", user);
        return res.data;
      });
  },

  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      localStorage.removeItem("ytc_lms_auth");
      delete axios.defaults.headers.common["Authorization"];
      resolve("logged out");
    });
  },

  async get_user({ commit }) {
    let user = JSON.parse(localStorage.getItem("ytc_lms_auth"));

    if (!user) {
      localStorage.removeItem("ytc_lms_auth");
      return;
    }

    let token = user.token;
    let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = bearer;

    return axios
      .get(url.user_detail)
      .then((res) => res.data)
      .then((res) => {
        let user = res.data;
        user.token = token;

        localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
        commit("SET_USER", user);
      })
      .catch((error) => {
        commit("RESET_USER");
        localStorage.removeItem("ytc_lms_auth");
        delete axios.defaults.headers.common["Authorization"];
        console.error(error);
      });
  },
};

const getters = {
  user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token,
};

export default { state, mutations, actions, getters };
