<template>
	<v-dialog
		v-model="dialog_show"
		persistent
		max-width="600px"
	>
		<v-card>
			<v-card-title>
				<span class="text-h6">Berhasil Upload File</span>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text>
				<br /><br /><br /><br />
				<div class="pa-3 text-center">
					<img src="@/assets/images/success.png" class="text-center mb-2" /><br />
					<h3 class="font-weight-bold">
						File berhasil diunggah
					</h3>
					<p class="">
						File hasil pengerjaan tugas praktik mandiri telah berhasil diunggah
					</p>
				</div>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn
					:loading="btn_loading"
					dark
					color="primary"
					small
					depressed
					@click="dialogHide()"
				>
				Oke
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
	},
	data() {
		return {
			dialog_show: false,
			btn_loading: false,
		}
	},
	methods: {
		dialogShow() {
			this.dialog_show = true
		},
		dialogHide() {
			this.btn_loading = true
			this.$emit('getActFalse')
			setTimeout(() => {
				this.btn_loading = false
				this.dialog_show = false
			},1500)
		},
	}
}
</script>
