<template>
  <div>
    <div v-if="input" class="d-flex justify-space-between align-end mt-2" style="position: relative;">
      <v-textarea style="font-size: 12px" :placeholder="`Tuliskan pesan di sini`" v-model="text" rows="3"
        outlined auto-grow>
      </v-textarea>
      <v-btn 
        x-small 
        class="my-2"
        style="position: absolute; bottom: 30px; right: 15px;" 
        color="primary" 
        :loading="isLoading"
        @click="handleSendDiscussion">
        Kirim
      </v-btn>
    </div>
    
    <v-list-item v-if="!input" class="py-0 mb-3 ml-0 px-0" two-line>
      <v-list-item-avatar class="pt-0 mt-0">
        <v-avatar
          class="flex-grow-0 text-uppercase white--text"
          color="primary"
          :size="size"
          :title="data.author_name">
          {{ data.author_name ? data.author_name[0] : this.$store.state.auth.user.fullname[0] }}
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="py-0">
        <v-list-item-title>
          <div class="font-weight-bold" style="font-size:13px;color: #0D082C;">{{ data.author_name ? data.author_name : this.$store.state.auth.user.fullname }}</div>
        </v-list-item-title>
        <v-list-item-subtitle style="font-size: 11px;color: #0D082C; max-width: 40rem; line-height: 18px;"
          class="text-wrap mb-1">
          {{ data.content }}
        </v-list-item-subtitle>
        <v-list-item-subtitle style="font-size: 11px; gap: 7px" class="d-flex">
          {{ convertDate(data.created_at) }}
          <div class="primary--text font-weight-medium" style="cursor: pointer;"
            @click="onClickReplay()">{{ reply ? 'Batal' : 'Balas' }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-expand-transition>
      <input-discussion
        v-if="reply"
        :size="25"
        input
        @click:send="handleReply"
      />
    </v-expand-transition>

    <v-expand-transition>
      <div v-show="childshow" class="ml-4 pl-4" style="border-left: 2px solid #F2F2F2">
        <input-discussion
        v-for="(item, index) in data.children || []"
        child
        :data="item"
        :size="24"
        :key="index"
        @click:send="$emit('click:send', $event)"
        />
      </div>
    </v-expand-transition>

    <v-btn 
      text
      x-small
      color="primary"
      class="caption" 
      v-if="data.total_child && data.total_child != null && data.total_child > 0"
      @click.prevent="childshow = !childshow">
      <v-icon left color="primary">{{ childshow ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill'}}</v-icon>
      <span class="font-weight-bold">{{ childshow ?  `Tutup Balasan` : `Lihat ${ data.total_child } Balasan` }}</span>
    </v-btn>

  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "input-discussion",
  props: {
    size: {
      type: [Number, String],
      default: 32,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    input: Boolean,
    child: Boolean,
    children: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    reply: false,
    childshow: false,
    isLoading: false,
    text: "",
    replyName: "",
  }),
  computed: {
  },
  methods: {
    convertDate(created_at) {
      return moment(created_at).format('DD MMM YYYY HH:mm')
    },
    onClickReplay(){
      this.reply = !this.reply
    },
    handleSendDiscussion() {
      this.isLoading = true
      this.$emit('click:send', this.text)
      setTimeout(() => {
        this.text = "";
        this.reply = false;
        this.isLoading = false
      }, 100);
    },
    handleReply(text) {
      this.isLoading = true
      this.$emit("click:send", { content: text, data: this.data  });
      setTimeout(() => {
        this.text = "";
        this.reply = false;
        this.isLoading = false
      }, 100);
    },
  },
};
</script>
