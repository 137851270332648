<template>
  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
    <div class="font-weight-bold mb-3">{{ act.title }}ss</div>
    <v-card elevation="0" class="col-md-12">
      <div class="pa-4">
        <div class="pa-4">
          <p
            class="text-body-1 text-justify mb-4"
            v-html="this.act.theory.description"
          ></p>
          <template v-if="this.act.theory.meeting.days != null">
            <div
              v-for="(item, index) in this.act.theory.meeting.days"
              :key="index"
            >
              <p>
                Hari {{ day(item.day) }} ,
                <span v-for="(time, index2) in item.times" :key="index2"
                  >pukul {{ time.start_time }} - {{ time.end_time }} WIB</span
                >
              </p>
            </div>
          </template>
          <template v-if="this.act.theory.meeting.dates != null">
            <div
              v-for="(item, index) in this.act.theory.meeting.dates"
              :key="index"
            >
              <p>
                Tanggal {{ date(item.date) }} ,
                <span v-for="(time, index) in item.times" :key="index + 'T'"
                  >pukul {{ time.start_time }} - {{ time.end_time }} WIB</span
                >
              </p>
            </div>
          </template>
          <v-card elevation="0" class="py-3 rounded-lg">
            <iframe
              v-if="this.act.theory.link_embed.url != ''"
              :src="this.act.theory.link_embed.url"
              style="height: 500px; border-style: hidden"
            ></iframe>
          </v-card>
          <p class="pt-1 mb-1" v-if="act.theory.meeting.link != ''">
            Link Meeting :
          </p>
          <template v-if="showMeetingLink">
            <v-btn
              small
              color="primary"
              dark
              depressed
              class="white px-2 rounded-lg"
              :href="this.act.theory.meeting.link"
              target="_blank"
              v-if="act.theory.meeting.link != ''"
              @click="handleClickMeetingLink"
            >
              Klik Disini
            </v-btn>
          </template>

          <div v-if="act.theory.meeting.link != ''" class="mt-3">
            <div v-for="(item, index) in metaData" :key="'A' + index">
              <p>{{ item.title }} : {{ item.content }}</p>
            </div>
          </div>

          <template v-if="finishSchedule && act?.theory?.meeting?.is_clicked">
            <div class="pt-3">Segera klik "Selesaikan Aktivitas" setelah jadwal live webinarmu berakhir!:</div>
            <!-- <div v-if="finishSchedule.is_day == '1'">
              <div v-for="(item, index) in finishSchedule.days" :key="index">
                <p>
                  Hari {{ day(item.day) }},
                  <span
                    >pukul {{ item.start_time }} - {{ item.end_time }} WIB</span
                  >
                </p>
              </div>
            </div>
            <div v-else-if="finishSchedule.is_day == '2'">
              <div v-for="(item, index) in finishSchedule.dates" :key="index">
                <p>
                  Tanggal {{ date(item.date) }},
                  <span
                    >pukul {{ item.start_time }} - {{ item.end_time }} WIB</span
                  >
                </p>
              </div>
            </div> -->
          </template>

          <template v-if="isFinishSchedule">
            <v-btn
              depressed
              class="px-2 rounded-lg"
              color="primary"
              :loading="btn_loading"
              @click="getUnlock()"
            >
              Selesaikan Aktivitas
            </v-btn>
          </template>
        </div>
      </div>
    </v-card>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    act: Object,
    detail_class: Object,
    detail_topic: Object,
    id_topic: String,
    id_act: String,
  },
  data() {
    return {
      btn_loading: false,
      is_clicked_btn: false,
    };
  },
  computed: {
    showMeetingLink() {
      const config = this.act?.theory?.meeting;
      if (!this.act.theory.meeting.link) return false;
      // nek disembunyikan berarti muncul nek pas sesuai jadwal
      if (config && config.is_must_participate) {
        const theDate = new Date();
        const days = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        const tanggal = `${theDate.getFullYear()}-${(theDate.getMonth() + 1)
          .toString()
          .padStart(2, 0)}-${theDate.getDate().toString().padStart(2, 0)}`;
        // hs = hari sekarang
        const hs = theDate.getDay();
        // jbs = jam berapa sekarang
        const jbs = `${theDate.getHours().toString().padStart(2, "0")}:${theDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;

        switch (config.is_day) {
          case false:
            return config.dates.reduce((car, cur) => {
              if (tanggal == cur.date.slice(0, 10))
                return (
                  car ||
                  cur.times.reduce((car2, cur2) => {
                    return (
                      car2 || (jbs >= cur2.start_time && jbs <= cur2.end_time)
                    );
                  }, false)
                );
              return car;
            }, false);
          case true:
            return config.days.reduce((car, cur) => {
              const dayIndex = days.findIndex((v) => v == cur.day);
              if (dayIndex == hs)
                return (
                  car ||
                  cur.times.reduce((car2, cur2) => {
                    return (
                      car2 || (jbs >= cur2.start_time && jbs <= cur2.end_time)
                    );
                  }, false)
                );
              return car;
            }, false);
          default:
            return false;
        }
      }
      // nek gak disembunyikanberarti muncul terus
      // nek link e kosong berarti ra muncul
      return true;
    },
    isFinishSchedule() {
      if (!this.finishSchedule) return true;
      const theDate = new Date();
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const daySchedule = this.finishSchedule.days || [];
      const dateSchedule = this.finishSchedule.dates || [];
      let value = false;

      switch (this.finishSchedule.is_day) {
        case "1":
          for (let index = 0; index < daySchedule.length; index++) {
            const theDay = daySchedule[index];
            const dayIndex = days.findIndex((v) => v == theDay.day);
            if (theDate.getDay() == dayIndex) {
              const time = `${theDate
                .getHours()
                .toString()
                .padStart(2, "0")}:${theDate
                .getMinutes()
                .toString()
                .padStart(2, "0")}`;
              console.log(time, theDay.end_time);
              if (time >= theDay.start_time && time <= theDay.end_time) {
                value = true;
                break;
              }
            }
          }
          break;
        case "2":
          for (let index = 0; index < dateSchedule.length; index++) {
            const theDay = dateSchedule[index];
            console.log(
              theDay.date,
              "==",
              `${theDate.getFullYear()}-${(theDate.getMonth() + "1").padStart(
                2,
                0
              )}-${theDate.getDate().toString().padStart(2, 0)}`
            );
            if (
              theDay.date ==
              `${theDate.getFullYear()}-${(theDate.getMonth() + 1)
                .toString()
                .padStart(2, 0)}-${theDate.getDate().toString().padStart(2, 0)}`
            ) {
              const time = `${theDate
                .getHours()
                .toString()
                .padStart(2, "0")}:${theDate
                .getMinutes()
                .toString()
                .padStart(2, "0")}`;
              if (time >= theDay.start_time && time <= theDay.end_time)
                value = true;
              break;
            }
          }
          break;
        default:
          break;
      }
      console.log(value);
      return (
        value &&
        this.act?.theory?.meeting?.is_clicked &&
        !this.act.is_complete &&
        !this.act.end_test_at
      );
    },
    finishSchedule() {
      const finishSchedule = this.act.theory.meta_data.find(
        (v) => v.title == "finish_activity"
      );
      if (finishSchedule && finishSchedule.content)
        return JSON.parse(finishSchedule.content);
      return {};
    },
    metaData() {
      return this.act.theory.meta_data.filter(
        (v) => v.title != "finish_activity"
      );
    },
  },
  methods: {
    day(a) {
      const day = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      const hari = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
      const index = day.findIndex((v) => v == a);
      return hari[index];
    },
    date(a) {
      return a ? moment(a).locale("en-gb").format("L") : null;
    },
    changeIsClickedBtn() {
      this.is_clicked_btn = true;
    },
    handleClickMeetingLink() {
      this.btn_loading = true;
      const data = {
        member_redeem_id: this.detail_class.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .put(`/users/v1/member/activity/update_meeting_is_clicked`, data)
        .then(() => {
          this.btn_loading = false;
          this.$emit("getActFalse", this.id_topic, this.id_act);
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    getUnlock() {
      let next_topic_at = this.detail_topic.next_topic_at
			let date_now = new Date()
			let next_topic_date = moment(next_topic_at).format('YYYY-MM-DD HH:mm:ss')
			let date_now_at = moment(date_now).format('YYYY-MM-DD HH:mm:ss')
			let next_topic_date_res = moment(next_topic_at).format('DD MMMM YYYY HH:mm:ss')
			
			// check if next topic is under next topci at
			if(next_topic_at != null && date_now_at < next_topic_date) {
        // Chek if this activity is last in topic
        if(this.act.is_last_activity) {
          return this.notification = {
            state: true,
            message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
          };
        }
      }

      this.btn_loading = true;
      const data = {
        program_id: this.$route.params.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.btn_loading = false;
          this.$emit("getActFalse", this.id_topic, this.id_act);
          this.$emit("getDetailClass");
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
  },
};
</script>