<template>
  <div id="chat-container" class="chat-container">
    <div id="chat-list-container" class="chat-list-container">
      <ChatList />
    </div>
    <div class="chat-input-container">
      <ChatInput :act="act" :topic="topic" :detailClass="detailClass" @error="showError" />
    </div>
    <v-snackbar v-model="snackbar.state" :color="snackbar.color" outlined top>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import ChatInput from './ChatInput';
import ChatList from './ChatList';

export default {
  name: "ChatContainer",
  components: {
    ChatInput, ChatList
  },
  props: {
    act: Object,
    topic: Object,
    detailClass: Object
  },
  data() {
    return {
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },
    }
  },
  methods: {
    showError(message) {
      this.snackbar.text = message;
      this.snackbar.state = true;
      this.snackbar.color = "error";

    }
  }
}
</script>
<style scoped>
.chat-container {
  width: 100%;
  position: relative;
}

.chat-list-container {
  /* height: calc(100% - 120px); */
  height: 500px;
  width: inherit;
  overflow-y: auto;
}

.chat-input-container {
  background-color: #ddd;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 4px;
  width: inherit;
  position: absolute;
  bottom: 0;
}
</style>