<template>
  <v-row align-content="center">
    <v-col class="py-0 mx-auto" md="12">
      <v-row>
        <v-col class="white px-12 mx-auto" md="6" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-4" justify="center">
              <v-col sm="6" lg="4" class="text-center">
                <img src="@/assets/images/success_verify.png">
              </v-col>
            </v-row>
            <div class="fs-32 mb-5 text-center font-weight-bold">
              Email Anda Berhasil Diverifikasi
            </div>
            <div class="fs-18 text-center color-disable">
              <span>Sistem kami telah berhasil memverifkasi email anda setelah ini anda akan diarahkan ke halaman login untuk mengakses akun anda</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "success-verify-email",
  mounted(){

    setTimeout( () => {
      this.$router.push({ path: '/login'})
      window.location.reload(true);
    }
    , 5000);

  },
};
</script>


<style>
</style>