<template>
  <div class="chat-list">
    <ChatLoader v-if="!isLoaded" />
    <v-card three-line v-if="isLoaded">
      <v-card-text style="overflow: auto;height: 430px;">
        <div class="text-center" v-if="chats.length <= 0">
          <div class="text-h6">Chat Masih Kosong</div>
          <div>Silahkan memulai chat untuk mendapatkan tanggapan dari Trainer</div>
        </div>
        <Chat v-for="chat in chats" :key="chat.id" :chat="chat" v-else />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import firebase from '@/firebase';
import ChatLoader from './ChatLoader';
import Chat from './Chat';

export default {
  name: "ChatList",
  components: {
    ChatLoader, Chat
  },
  data() {
    return {
      isLoaded: false,
      chats: []
    }
  },
  computed: {
    ...mapState('roomModule', {
      roomID: state => state.activeRoom
    })
  },
  created() {
    // Get the messages
    if (this.roomID) {
      firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ROOM)
      .doc(this.roomID).collection(process.env.VUE_APP_FIREBASE_COLLECTION_MESSAGE)
      .orderBy("created_at", "asc")
        .onSnapshot((snapshot) => {
          const newChats = [];
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              var chat = {
                id: change.doc.id,
                ...change.doc.data(),
              };

              newChats.push(chat);
            }
          });

          this.chats = this.chats.concat(newChats);
          this.isLoaded = true;
        });
    }
  },
  mounted() {
    // var element = document.getElementById("chat-container");
    // function resize() {
    //   var bodyheight = window.innerHeight - 64;

    //   if (typeof (element) != 'undefined' && element != null) {
    //     element.style.height = bodyheight + "px";
    //   }
    // }

    // window.addEventListener("resize", resize);
    // resize();
  }
}
</script>
<style scoped>
.chat-list {
  height: inherit;
}
</style>