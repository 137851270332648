<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col align="center">
        <div class="text-h6">Chat masih kosong</div>
        <div>Silahkan mulai chat kepada trainer</div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "RoomNotSelected"
}
</script>