<template>
  <div v-if="isLoading && title && description">
    <v-row style="height: 100vh;" class="pa-5" justify="center" align="center">
      <v-card class="pa-3 text-center" height="70vh" width="80vw" flat>
        <v-row justify="center" align="center" style="height: 100%;">
          <v-col>
            <img v-if="success" :src="require('@/assets/images/icon/check-circle.png')" alt="" srcset="" class="mx-auto" width="150">
            <img v-else :src="require('@/assets/images/icon/circle-x.png')" alt="" srcset="" class="mx-auto" width="150">
    
            <div class="my-8 text-h5 text-uppercase font-weight-black">{{ title }}</div>
            <div class="my-8 text-capitalize">{{ description }}</div>
            <v-btn 
              class="primary"
              @click="$router.push('/kelas')">
              Kembali ke Pelatihan
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
  <NotFound v-else/>
</template>

<script>
import NotFound from '../errors/NotFound.vue'
export default {
  components: {
    NotFound
  },
  data() {
    return {
      isLoading: true,
      success: false,
      state: "",
      title: "Loading ....",
      error_code: "",
      description: "Silahkan tunggu sebentar",
    }
  },
  created() {
    if(Object.keys(this.$route.query).length > 0) {
      this.isLoading = true
      if(this.$route.query.error_code) {
        this.success = false
        this.title = this.$route.query.error
        this.description = this.$route.query.error_description
        this.state = this.$route.query.state
        this.onRedeemCallback()
      }else {
        this.success = true
        this.state = this.$route.query.state
        this.onRedeemCallback() 
      }
    }else {
      this.isLoading = false
    }
  },
  methods: {
    encodingUrl(params) {
      let query = '?' +
      Object.keys(params)
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          )
        })
        .join('&')
      return query
    },
    async onRedeemCallback() {
      this.isLoading = true
      await this.axios.get(`/users/v1/myprogram/check-status-prakerja/${this.state}${this.encodingUrl(this.$route.query)}`).then((response) => {
        let res = response.data
        if(res.data.status) {
          this.success = true
          this.title = "Redeem Kode Berhasil"
          this.description = "Selamat Anda Telah Berhasil Melakukan Redeem Kode Prakerja"
          this.isLoading = false
        }else {
          // KETIKA GAGAL TAMPILKAN PESAN KESALAHAN
          this.success = false
          this.title = "Redeem Kode Gagal"
          this.description = "Terjadi Kesalahan pada sistem"
          this.isLoading = false
        }
      }).catch((error) => {
        // console.log(error);
        this.success = false
        this.title = "Redeem Kode Gagal"
        this.description = error.response.data.message ? error.response.data.message : "Data Redeem tidak ditemukan"
      })
    }
  }
}
</script>