<template>
  <v-row class="mt-0">
    <template v-if="isSubscribe == 1">
      <v-col cols="12">
        <div class="d-flex">
          <div class="text-body-1 cl-slate">
            Kelas - kelas yang tersedia dibawah ini dapat Anda akses hingga tanggal
            <span class="font-weight-bold cl-black">{{ formatDate(currentPackage.expired_at) }}</span>
          </div>
          <router-link to="/subscription" class="ml-2 font-weight-bold">
            Lihat paket subskripsi saya
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" v-if="currentPackage.status !== 'active'">
        <v-card class="rounded-lg card-box-shadow">
          <v-card-text>
            <v-row align="center" justify="space-between">
              <div class="pa-3">
                <div class="text-body-1 cl-black font-weight-bold">
                  Paket berlangganan Anda telah habis
                </div>
                <div class="text-body-1 cl-slate">
                  Akses kelas Anda kembali dengan membeli voucher paket berlangganan
                </div>
              </div>
              <div class="pa-3">
                <a href="https://shop.yec.co.id/" target="_blank">
                  <v-btn color="primary" elevation="0" rounded>Beli Paket Berlangganan</v-btn>
                </a>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <template v-if="myProgram.list.length > 0">
        <v-col md="4" lg="3" cols="6" v-for="(list, index) in myProgram.list" :key="index" class="mb-3">
          <v-card elevation="0" class="rounded-lg" @click="goDetailClass(list)">
            <v-img
              height="150"
              :src="list.program.program_information.cover.url"
            ></v-img>
            <div class="pa-4">
              <div class="font-weight-bold mb-1 text-dots-2" style="min-height: 48px;">
                {{ list.program.title }}
              </div>
              <h3 class="teal--text font-weight-bold mb-2">Rp. {{ formatPrice(list.program.program_information.selling_price) }}</h3>
              <div class="d-flex align-center mb-2">
                <v-progress-linear
                  class="mr-2"
                  color="primary"
                  background-color="grey lighten-3"
                  height="6"
                  rounded
                  :value="list.results.progress"
                ></v-progress-linear>
                <div class="navy-soft--text">{{ list.results.progress }}%</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col v-if="myProgram.total >= 12 " md="12" lg="12" cols="12" class="mx-auto text-center py-3">
          <v-btn depressed :loading="btnLoading" color="primary" dark class="rounded-xl" @click="getLoadClass(myProgram.limit +=12)">Tampilkan Lebih Banyak</v-btn> 
        </v-col>
      </template>
      <v-col v-else cols="12" class="d-flex justify-center">
        <div class="text-center">
          <div class="text-h6 cl-black">Anda belum berlangganan</div>
          <div class="pt-4 pb-5 text-body-1 cl-black">
            Untuk mendapatkan akses ke semua kelas, <br>silakan beli paket berlangganan Anda
          </div>
          <a href="https://shop.yec.co.id/" target="_blank">
            <v-btn color="primary" elevation="0" rounded>Beli Paket Berlangganan</v-btn>
          </a>
        </div>
      </v-col>
    </template>
    <template v-if="isSubscribe == 0">
      <template v-if="myProgram.list.length > 0">
        <v-col md="4" lg="3" cols="6" v-for="(list, index) in myProgram.list" :key="index" class="mb-3">
          <v-card elevation="0" class="rounded-lg" @click="goDetailClass(list)">
            <v-img
              height="150"
              :src="list.program.program_information.cover.url"
            ></v-img>
            <!-- <v-card-text class="pa-1 banner-class">
              <div class="white--text font-weight-bold px-4 subtitle-2 text-no-wrap">
                {{ list.program.class_type == 1 ? 'Webinar' : 'Self-Paced Learning'  }}
              </div>
            </v-card-text> -->
            <div class="pa-4">
              <div class="font-weight-bold mb-1 text-dots-2" style="min-height: 48px;">
                {{ list.program.title }}
              </div>
              <h3 class="teal--text font-weight-bold mb-2">Rp. {{ formatPrice(list.program.program_information.selling_price) }}</h3>
              <div class="d-flex align-center mb-2">
                <v-progress-linear
                  class="mr-2"
                  color="primary"
                  background-color="grey lighten-3"
                  height="6"
                  rounded
                  :value="list.results.progress"
                ></v-progress-linear>
                <div class="navy-soft--text">{{ list.results.progress }}%</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col v-if="myProgram.total >= 12 " md="12" lg="12" cols="12" class="mx-auto text-center py-3">
          <v-btn depressed :loading="btnLoading" color="primary" dark class="rounded-xl" @click="getLoadClass(myProgram.limit +=12)">Tampilkan Lebih Banyak</v-btn> 
        </v-col>
      </template>
      <v-col v-else>
        <v-row justify="center">
          <v-col md="4" sm="6">
            <v-img
              class="col-8 mx-auto mt-5 mb-4"
              src="@/assets/images/data_empty.png"
            ></v-img>
            <h6 class="text-h6 text-center mb-5 cl-black">Belum ada kelas yang di ikuti</h6>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: ['isSubscribe'],
  emits: ['loadContentTrue', 'loadContentFalse'],
  data() {
    return {
      btnLoading: false,
      myProgram: {
        list: [],
        total: 0,
        page: 1,
        limit: 12,
        is_from_subscribe: 0,
        sort: 'created_at',
        dir: 'desc'
      },
      currentPackage: {}
    }
  },
  methods: {
    formatDate(val) {
      return val ? moment(val).locale('en-gb').format("DD MMMM YYYY") : null;
    },
    getClass() {
      this.$emit('loadContentTrue')
      setTimeout(() => {
        if (this.isSubscribe == 1) {
          this.getCurrentPackage()
        }
        this.myProgram.is_from_subscribe = this.isSubscribe
        this.axios
        .get(`/users/v1/myprogram/list?is_from_subscribe=${this.myProgram.is_from_subscribe}&sort=${this.myProgram.sort}&dir=${this.myProgram.dir}&limit=${this.myProgram.limit}`)
        .then((response) => {
          let res = response.data.data;
          this.$emit('loadContentFalse')
          if (response.status == 200) {
            this.myProgram.list = res.list;
            this.myProgram.total = res.total;
          }
        });
      }, 100)
    },
    getCurrentPackage() {
      this.axios.get(`/subscribe/v1/current_package`)
      .then((response) => {
        if (response.status == 200) {
          this.currentPackage = response.data.data;
        } else {
          this.currentPackage = {}
        }
      })
    },
    getLoadClass(limit) {
      this.btnLoading = true
      this.axios
      .get(`/users/v1/myprogram/list?is_from_subscribe=${this.myProgram.is_from_subscribe}&sort=${this.myProgram.sort}&dir=${this.myProgram.dir}&limit=${limit}`)
      .then((response) => {
        this.btnLoading = false
        let res = response.data.data;
        if (response.status == 200) {
          this.myProgram.list = res.list;
        }
      });
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goDetailClass(list) {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // check navigator is from iphone
      if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") > -1) {
        this.$router.push({ name: 'detail-class', params: { id: list.id } })
      } else {
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
          this.$router.push({ name: 'detail-class-v2', params: { id: list.id } })
        } else {
          this.$router.push({ name: 'detail-class-v2', params: { id: list.id } })
        }
      }
    },
  }
}
</script>
<style>
.banner-class {
  background: #e65c00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #f9a023, #324577);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #f9a023, #324577); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>