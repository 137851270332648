<template>
	<v-dialog v-model="dialog_show" persistent max-width="600px">
		<v-card color="primary" dark>
			<v-card-text>
				Please stand by
				<v-progress-linear
					indeterminate
					color="white"
					class="mb-0"
				></v-progress-linear>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
	},
	data() {
		return {
			dialog_show: false,
		}
	},
	methods: {
		dialogShow() {
			this.dialog_show = true
		},
		dialogHide() {
			this.dialog_show = false
		},
	}
}
</script>