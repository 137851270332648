<template>
  <div class="room-list" style="height: 500px; overflow: auto;">
    <v-skeleton-loader v-if="!isLoaded" type="list-item-avatar-two-line" class="mx-auto"></v-skeleton-loader>
    <Room v-for="room in sortedRooms" :key="room.id" :room="room" :online="room.online" :active="room.active" :id_program="id_program" @click:selectedRoom="$emit('click:selectedRoom', room)"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Room from './Room';
import firebase from '@/firebase';

export default {
  name: "RoomList",
  components: {
    Room
  },
  props: {
    id_topic: String,
    id_act: String,
    id_program: String
  },
  data() {
    return {
      isLoaded: false,
      rooms: {},
      onlineUsers: [],
      onlineUserIDs: [],
    }
  },
  created() {
    // console.log('this.id_program',this.id_program);
    // console.log('this.$store.state.auth.user.id',this.$store.state.auth.user.id);
    
    
    // Get all the users
    firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ROOM)
      .where("program_id", "==", this.id_program)
      .where("member.id", "==", this.$store.state.auth.user.id)
      .onSnapshot((snapshot) => {
        const rooms = [];
        snapshot.forEach((doc) => {
          const room = doc.data();
          room.id = doc.id
          rooms.push(room);
        });

        this.rooms = rooms;
        this.isLoaded = true;
      });
      
      
      // GET ONLINE USER
      // let datetime = new Date();
      // datetime.setHours(datetime.getHours() - 2);
      firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ONLINE)
      // .where('login_at', '>=', datetime)
      .onSnapshot((snapshot) => {
        const users = []
        const userIDs = []
        snapshot.forEach((doc) => {
          const onlineTrainer = doc.data();
            users.push(onlineTrainer)
            userIDs.push(onlineTrainer.id)
        });

        this.onlineUsers = users
        this.onlineUserIDs = userIDs
      });
      
  },
  computed: {
    // ...mapState('userModule', {
    //   userState: state => state.user
    // }),
    ...mapState('trainerModule', {
      trainers: state => state.trainers
    }),
    ...mapState('roomModule', {
      roomID: state => state.activeRoom
    }),
    sortedRooms() {
      var activeRooms = [];
      var inactiveRooms = [];
      var rooms = this.rooms;

      for (var i = 0; i < rooms.length; i++) {
        var room = rooms[i];
        // Preprocessing: Build the room name and photo
        // var names = [];
        // var trainers = this.trainers.filter(val => {
        //   // Remove self and filter contacts that are in the room
        //   return this.userState.ID != val.id && room.users.indexOf(val.id) >= 0;
        // });

        // check data online users 
        if( this.onlineUserIDs.indexOf(room.trainer.id) >= 0) {
          room.online = true
        }
        else {
          room.online = false
        }

        // for (var j = 0; j < trainers.length; j++) {
        //   names.push(trainers[j].name);
        // }
        // room.name = names.join(', ');

        // if (room.isPrivate) {
        //   room.photoUrl = trainers[0] ? trainers[0].photoUrl : "";
        // }

        // For active room
        if (room.id == this.roomID) {
          room.active = true;
          activeRooms.push(room);
        } else {
          // For inactive room
          room.active = false;
          inactiveRooms.push(room);
        }
      }

      return [...activeRooms, ...inactiveRooms];
    },
  }
}
</script>