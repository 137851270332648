<template>
	<transition name="fade">
		<v-app>
			<div class="grey" style="height: 100vh;">
				<v-main class="grey">

					<v-container fluid>
						<transition name="fade">
							<div class="col-12 col-sm-6 mx-auto">
								<div class="">
									<v-card colored-border elevation="0" class="transparent">
										<p class="text-center mb-0">
											<img src="@/assets/images/maintenance.png" style="width: 100%;" />
										</p>
										<h1 class="text-center">Site Under Maintenance</h1>
										<p class="text-center">Halaman sedang dalam perbaikan oleh tim kami <br>mohon mencoba beberapa saat lagi</p>
									</v-card>
								</div>
							</div>
						</transition>
					</v-container>

				</v-main>
			</div>
		</v-app>
	</transition>
</template>
<script>

export default {

};
</script>
