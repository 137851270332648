import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyD852oeQzdqEwfvwPiF2fbHGpNY95Dzon0",
  authDomain: "lp-profesi.firebaseapp.com",
  projectId: "lp-profesi",
  storageBucket: "lp-profesi.appspot.com",
  messagingSenderId: "423546721975",
  appId: "1:423546721975:web:6a580cb6dbd6ab48b91f32",
  measurementId: "G-10M5BPX9GV"
};

firebase.initializeApp(firebaseConfig)

export default firebase